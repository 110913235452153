// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import moment from "moment"
import  $ from "./jquery.min"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import {TheMask} from 'vue-the-mask'
import axios, * as other  from "axios";

window.moment = moment;
window.DatePicker = DatePicker;
window.TheMask = TheMask;
window.axios = axios;

Rails.start();
Turbolinks.start();
ActiveStorage.start();
$(document).ready(function () {
    $("#mobileMenu ").click(function() {
        console.log($("#mobileMenu").data("status"));
      if ($("#mobileMenu").data("status") == "close") {
          $("#mobileMenu").data("status", "open");
          $("#mobileMenuOpen").addClass("d-none");
          $("#mobileMenuClose").removeClass("d-none");
          $("#mobileMenuContainer").animate({
              height: "156px"
          }, 500)

      } else if ($("#mobileMenu").data("status") == "open") {
          $("#mobileMenu").data("status", "close");
          $("#mobileMenuOpen").removeClass("d-none");
          $("#mobileMenuClose").addClass("d-none");
          $("#mobileMenuContainer").animate({
              height: "20px"
          }, 500)
      }
      return false;
    });
})